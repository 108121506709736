.featured-property-slider {
    .property-details {
        margin-top: 10px;

        li {
            font-size: 13px;
        }
    }

    p {
        &.property-price {
            margin-top: 30px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    a {
        &.property-link {
            font-size: 15px;
            font-weight: 500;
            border: 1px solid rgba(255, 255, 255, 0.3);
            padding: 8px 18px;
            display: inline-block;
            text-transform: lowercase;

            &:hover {
                background: rgba(0, 0, 0, 0.3);
                border-color: #FFF;
            }

            i {
                font-size: 16px;
                margin-left: 5px;
                position: relative;
                top: 1px;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }
}