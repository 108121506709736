@mixin box-drop-shadow() {
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 3px 0;
}

#currency-dropdown {
    background: transparent;
    border: none;
    font-size: 14px;
    color: #cccccc;
    margin-right: 10px;
    position: relative;
    top: 5px;
    outline: none;

    &:hover {
        cursor: pointer;
    }
}

.fa-star {
    &.required {
        font-size: 8px;
        color: color('primary');
        vertical-align: text-top;
    }
}

.page-navigation {
    li {
        font-size: 13px;

        &.back {
            border-right: 1px solid #d4d4d4;
            padding-right: 0.8rem;
            margin-right: 0.8rem;

            i {
                font-size: 15px;
                margin-right: 10px;
            }

            a {
                &.disabled {
                    opacity: 0.5;

                    &:hover {
                        text-decoration: none;
                        cursor: not-allowed;
                    }
                }
            }
        }

        &.share {
            i {
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }
}

.button {
    display: inline-block;

    &:hover {
        text-decoration: none;
    }

    &.contact-button {
        background: color('primary');
        font-size: 14px;
        color: #FFF;
        text-transform: uppercase;
        padding: 1rem;
        border: 1px solid color('primary');

        i {
            font-size: 16px;
            margin-left: 0.8rem;
        }

        &:hover {
            color: color('primary');
            background: #FFF;
        }
    }
}

#load-more-project,
#load-more {
    margin: 0 auto;
    border: 2px solid color('primary');
    padding: 10px 20px;
    display: none;
    color: color('primary');

    &.visible {
        display: block;
    }

    span {
        margin-left: 5px;
        display: none;
    }

    &.active,
    &:hover {
        background: color('primary');
        color: #FFF;
    }
}

.contact-form {
    input {
        padding: 8px 10px;
        height: auto;
    }

    textarea {
        min-height: 100px;
    }

    .checkbox {
        input {
            width: 20px;
            height: 20px;
            border: 1px solid #d7d7d7;
            margin-right: 5px;
            position: relative;
            top: 4px;
        }
    }

    &.square {
        input {
            border-radius: 0;
        }
    }
}

.brochures-list {
    margin-bottom: 0;

    li {
        margin-bottom: 10px;

        a {
            color: color('primary');
            font-size: 15px;
            font-weight: 500;

            i {
                margin-right: 15px;
                font-size: 20px;
                position: relative;
                top: 2px;
            }

            span {
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
}