#saving-search-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  &.modal-hide {
    display: none;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    border: 1px solid rgba(color('primary'), 0.3);
    width: 450px;

    @media(max-width: 768px) {
      width: 370px;
    }

    @media(max-width: 576px) {
      width: 330px;
    }


    .modal-top {
      padding: 20px;
      background-color: color('background', '', '', $colors-saving-search-modal);
      color: #eee;


      p {
        margin: 0;
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        width: 49.99%;
        line-height: 2;
      }

      .close {
        color: #fff;

        span {
          font-size: 40px;
        }
      }

      span {
        width: 49.99%;
        text-align: end;

        i {
          font-size: 20px;
          line-height: 1.5;
          cursor: pointer;
        }
      }
    }

    .modal-form {
      padding: 20px;

      label {
        font-size: 15px;
      }

      .input-wrapper {
        position: relative;

        input {
          padding: 8px;
          margin-bottom: 15px;
          width: 100%;
        }

        &::after {
          position: absolute;
          z-index: 2;
          top: -8px;
          right: -5px;
          font-family: FontAwesome;
          font-size: 9px;
          content: "\f005";
          color: color('modal-stars', '', '', $colors-saving-search-modal);
        }

      }


      select {
        padding: 10px;
        margin-bottom: 15px;
        width: 100%;
      }

      .modal-save-btn {
        padding: 10px 18px;
        text-transform: uppercase;
        color: #fff;
        background-color: color('btn-bg', '', '', $colors-saving-search-modal);
        font-size: 15px;
        font-weight: 300;

        &[disabled] {
          background: #8896b0;
        }

        &[disabled]:hover {
          cursor: default;
        }

        i {
          padding-left: 10px;

          &::before {
            content: "\f0c7";
          }
        }

      }

      .modal-save-search-bottom {
        margin-top: 10px;
        span {

          line-height: 3;

          i {
            position: relative;
            padding: 0 5px;

            &:before {
              position: absolute;
              top: -13px;
              left: -0;
              content: "\f005";
              font-size: 9px;
              color: color('primary');
            }
          }
        }
      }


    }
  }

  #modal-success-message {
    padding: 20px 20px 0 20px;
    font-size: 15px;
    color: #151e3a;

    a {
      color: #151e3a;
      font-weight: 600;
      text-decoration: underline;
    }
  }


}