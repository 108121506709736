.SumoSelect {
    > .optWrapper {
        border: none;
        @include box-drop-shadow();
    }
}

.search-wrapper {
    position: relative;
    display: flex;
    // align-items: center;
    // justify-content: center;
    
    .main-header {
        display: inline-flex;
        align-items: center;
        width: 100%;

        .header-slider {
            width: 100%;
        }
    }

    .homepage-search {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10%;
    }
}

.homepage-search {
    .SumoSelect {
        &.sumo_type {
            .CaptionCont {
                display: none;
            }

            > .optWrapper {
                top: 0;
                border-radius: 0;
                width: 110%;

                > .options {
                    li {
                        label {
                            color: #777;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .search-box {
            form {
                .input-group {
                    box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
                }
            }

            .search-box-triggers {
                a {
                    text-shadow: 1px 1px 5px rgba(0, 0, 0, .7);
                }
            }
        }
    }
}

.search-box {
    .search-options {
        font-size: 15px;
        margin-bottom: 0;

        > li {
            background: color('search-box-bg-transparent', '', '', $colors-search);
            line-height: 1.5;
            padding: 14px 25px;
            margin-right: 1px;
            position: relative;

            &.action-item-wrapper {
                padding: 0;
            }

            &:hover {
                cursor: pointer;
                /*background-color: color('search-box-hover', '', '', $colors-search);*/
                transition: background-color 0.3s ease;
            }

            &.active {
                background: color('search-box-bg', '', '', $colors-search);

                &::after {
                    content: " ";
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 6px solid color('search-box-bg', '', '', $colors-search);
                    position: absolute;
                    bottom: -5px;
                    z-index: 2;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }

            &:first-of-type {
                &.active {
                    &::after {
                        display: none;
                    }
                }
            }

            ul {
                font-size: 15px;
            }
        }

        .action-type {
            li {
                position: relative;

                &:only-child{
                    display: none;
                }

                &.active {
                    /*&::after {
                        content: " ";
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 6px solid #000;
                        position: absolute;
                        bottom: -5px;
                        z-index: 2;
                        right: 0;
                        margin: 0 auto;
                        left: 0;
                    }*/
                }

                &:not(:last-child) {
                    margin-right: 0;
                    border-right: 1px solid rgba(255, 255, 255, 0.2);
                }

                label {
                    padding: 14px 25px;
                    font-size: 15px;
                    margin-bottom: 0;
                    text-transform: capitalize;
                    color: rgba(255, 255, 255, 0.9);
                    background-color: color('search-box-bg', '', '', $colors-search);

                    &.active {
                        color: color('search-box-text-light', '', '', $colors-search);
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            input[type=radio],
            input[type=checkbox] {
                display: none;

                &:checked + label {
                    opacity: 1;
                    position: relative;

                    &::after {
                        content: "";
                        height: 5px;
                        background: color('search-input', '', '', $colors-search);
                        position: relative;
                        display: block;
                        margin-bottom: -5px;
                        
                        @media (max-width: 992px) {
                            margin: 0 auto -5px;
                            width: 40%;
                        }
                    }
                }
            }
        }

        .property-type {
            .SumoSelect {
                display: none;
                position: absolute;
                z-index: 9;
                left: 0;
                top: 50px;

                &.visible {
                    display: block;
                }
            }
        }
    }

    .input-group-append{
        @media (max-width: 540px){
            display: none;
        }
    }

    input {
        font-size: 14px;
        line-height: 2.2;
        padding: 8px;
        height: auto;
        border: none;
        transition: height 0.5s linear;
        margin-left: 0 !important;

        &.hidden {
            display: none;
            height: 0;
            padding: 0;
        }
    }

    button {
        font-size: 18px;
        padding: 0 15px;
    }

    .search-box-triggers {
        margin: 15px 15px 0 0;
        font-size: 15px;
        text-align: right;

        i {
            font-size: 16px;
            margin-left: 10px;
        }

        &.group {
            font-size: 14px;

            i {
                color: color('search-i', '', '', $colors-search);
            }
        }
    }
}

.group-search {
    border-bottom: 1px solid #d4d4d4;

    .search-box {
        .input-col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 120px;
            flex-grow: 0;

            input {
                padding: 8px 15px;
                border-left: 1px solid #d4d4d4;
            }
        }

        .col, .col-2 {
            border-right: 1px solid #d4d4d4;
    }
  
        .locations-col {
            display: flex;
            align-items: center;
            justify-content: center;
}

        .locations-col {
            .select2-container {
                border-right: 1px solid #d4d4d4;
                padding: 4px 6px;
                height: 50px;
            }
        }

        .col,
        [class*="col-"] {
            padding: 0;
            line-height: 1;

            .SumoSelect {
                > .CaptionCont {
                    border: none;

                    > span {
                        line-height: 47px;
                    }

                    > label {
                        > i {
                            line-height: 48px;
                        }
                    }
                }

                > .optWrapper {
                    min-width: 101%;
                    width: auto;
                }

                &.open {
                    > .optWrapper {
                        top: 47px;
                        left: -1px;
                    }
                }
            }
        }
    }
}

.detail-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;

    .modal-dialog {
        max-width: 750px;
    }

    .modal-content {
        border: 0;
    }

    header {
        background: color('modal-header', '', '', $colors-search) !important;
        padding: 2rem;

        .title {
            font-size: 24px;
            font-weight: 300;
            line-height: 1;
            color: color('text-light', '', '', $colors-search);
        }

        .close {
            color: color('text-light', '', '', $colors-search);
            font-size: 38px;
            margin: -1.7rem -1rem -1.5rem auto;

            &:focus,
            &:hover {
                color: color('text-light', '', '', $colors-search) !important;
            }
        }
    }

    .search-box {
        padding: 1rem 1rem 0 1rem;

        input {
            border: 1px solid #d4d4d4;

            &.select2-search__field {
                min-width: 270px;

                @media (max-width: 375px) {
                    min-width: 200px;
                }
            }

            &:not(.autocomplete-field) {
                padding: 0 9px;
                line-height: 38px;
            }
        }

        span.select2-container {
            border: 1px solid #d4d4d4;
        }

        .search-filters {
            .filter-item {
                text-align: center;
                width: 152px;
                margin-right: 20px;
                margin-bottom: 10px;

                @media (max-height: 1080px) {
                    margin-right: 16px;
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }

                input {
                    display: none;

                    &:checked + label {
                        border-color: color('primary');
                    }
                }

                label {
                    border: 1px solid #d4d4d4;
                    font-size: 1rem;
                    line-height: 38px;
                    margin-bottom: 0;
                    width: 100%;
                    user-select: none;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            @media only screen and (max-width: 744px) {
                .filter-item {
                    width: 49%;
                    margin-right: 1%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
              }

              @media only screen and (max-width: 360px) {
                .filter-item {
                    width: 100%;
                    margin-right: 0;
                }
              }
        }
    }

    footer {
        padding: 1rem 2rem 2rem 2rem;
        justify-content: space-between;
        border: 0;

        @media (max-width: 992px) {
            display: block;
        }

        p {
            font-size: 18px;
            margin: 0;

            span {
                font-weight: 700;
            }
        }

        @media (max-width: 992px) {
            button {
                margin-top: 1rem;
            }
        }
    }
}

.range-col {
    border-right: 1px solid #d4d4d4;

    &.active {
    > p {
            background: color('background-light', '', '', $colors-search);
        }
    }

    > p {
        line-height: 35px;
        padding: 0 15px;
        margin: 6px 0;
        text-align: left;

        &:after {
            content: "\f107";
            background: none;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            width: auto;
            height: auto;
            position: relative;
            top: 2px;
            float: right;
        }
        
        &:hover {
            cursor: pointer;
        }
    }

    .range-wrapper {
        display: none;
        position: absolute;
        background: #FFF;
        border: 1px solid transparent;
        border-top: none;
        padding: 10px;
        width: 300px;
        left: -1px;
        margin: 1px 0 0 0;
        z-index: 9999;
        @include box-drop-shadow();

        &.active {
            display: inline-block;
        }

        li {
            width: 45%;

            input {
                border: 1px solid #d4d4d4;
                padding: 10px;
            }
        }
    }
}

.mobile-search-wrapper {
    .search-box {
        [class*="col-"] {
            border: 1px solid color('border-light', '', '', $colors-search);
        }

        .action-options-wrapper {
            background: color('search-box-bg', '', '', $colors-search);
            // margin-top: -1px;
            // margin-right: -1px;
            // margin-left: -1px;

            .action-option {
                color: color('text-light', '', '', $colors-search);

                label {
                    margin-bottom: 0;
                    text-transform: capitalize;
                    font-size: 15px;
                    opacity: 0.5;
                    width: 100%;
                    line-height: 1.5;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &.active {
                    label {
                        opacity: 1;
                    }
                }
            }
        }
    }

    button {
        &[type=submit] {
            width: 100%;
            padding: 0.6rem 0;
        }
    }

    .search-box-triggers {
        border: none !important;

        i {
            color: color('search-i', '', '', $colors-search);
        }
    }
}

.btn-search-trigger {
    font-size: 14px;

    i {
        margin-left: 10px;
    }
}

.search-bottom-container {
    @media (max-width: 992px) {
        display: none;
    }
}

.select2-container--default .select2-search--inline .select2-search__field {
    min-width: 240px;
}

//hiding search button on modul draw map

#property-map-view {
    .group-search {
        .btn-search {
            display: none;
        }
    }
}