@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";

.mCSB_scrollTools {
    .mCSB_draggerContainer {
        left: 0;
        right: 8px;
        top: 6px;

        &:hover {
            .mCSB_dragger {
                .mCSB_dragger_bar {
                    height: 2px;
                    margin: auto;
                }
            }
        }
    }

    .mCSB_scrollTools_horizontal {
        height: 2px;
    }

    &.mCSB_scrollTools_horizontal {
        height: 14px;
    }
}

.mCSB_horizontal {
    .mCSB_inside {
        > .mCSB_container {
            // margin-bottom: 0;
            // max-height: 47px;
        }
    }
}