.generate-links-wrapper {
    .add-to-link-list {
        display: block;
        background: color('primary');
        border-top: 1px solid #d4d4d4;
        padding: 1.25rem 1.25rem;
        font-size: 14px;
        color: color('white');
        margin-right: -1px;
        margin-left: -1px;

        i {
            margin-right: 10px;
        }

        &.selected,
        &:hover {
            text-decoration: none;
            background: color('secondary');
            color: color('primary');
        }
    }
}

.add-to-link-list {
    font-weight: 400;

    &.selected,
    &:hover {
        font-weight: 900;
    }
}

.property-card {
    &.incognito {
        .card-heading {
            .remove-from-link-list {
                background: #e02f2f;
                font-size: 20px;
                padding: 10px 12px;
                display: inline-block;
                line-height: 1;
                position: absolute;
                right: -1px;
                top: -1px;
                z-index: 1;

                &:hover {
                    background: #ce2c2c;
                }

                i {
                    color: color('white');
                }
            }

            .card-img-overlay {
                ul {
                    li {
                        line-height: 17px;
                    }
                }
            }
        }
    }
}

.generate-links-page-link-wrapper {
    opacity: 0;
    position: fixed;
    bottom: 20px;
    width: 0;
    z-index: 9;
    transition: all ease-in 1s;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    overflow: hidden;

    &.visible {
        width: 250px;
        opacity: 1;
    }

    a {
        padding: 1.25rem;
        display: block;
        color: color('white');
        background: #bb2a2a;
        width: 300px;

        i {
            margin-right: 10px;
        }

        &:hover {
            text-decoration: none;
            background: #8f2121;
        }
    }
}

.send-generated-links-section {
    background: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 30px;

    p {
        color: color('text', 'dark');
    }

    #custom-links-form {
        input,
        button {
            font-size: 14px;
            border-radius: 0;
            height: 42px;
        }

        button {
            color: color('white');
            background: color('primary');
            text-transform: uppercase;
            padding-left: 15px;
            padding-right: 15px;

            i {
                margin-left: 15px;
                font-size: 16px;
            }
        }
    }

    .addthis_inline_share_toolbox {
        .at-share-btn-elements {
            > a {
                border-radius: 0 !important;

                &.at-svc-facebook,
                &.at-svc-linkedin {
                    display: none;
                }
            }
        }
    }
}