@media print {
    @page {
        size: A4;
        margin: 0;
        position: relative;
        /*margin: 0 !important;
        margin-top: 1cm !important;
        margin-bottom: 0cm !important;
        margin-left: 0cm !important;
        margin-right: 0cm !important;*/
    }

    html, body {
        width: 210mm;
        height: 297mm;
    }
}

.print-page {
    &.first-page-content {
        //height: 1233px;
    }

    .header-list li {
        display: inline;
        position: relative;
        top: 70px;
        left: 660px;
        font-size: 14px;
        margin-right: 0.2em;
        background-color: #50b93b;
        color: white;
        padding-left: 0.4em;
        padding-right: 0.4em;
        padding-bottom: 0.1em;
    }

    .header-list li:nth-child(2) {
        background-color: #d4af33;
    }

    .header-list li:nth-child(3) {
        background-color: #e75e5e;
    }

    .side-images {
        img {
            &:last-child {
                margin-top: 28px;
            }
        }
    }

    h2 {
        font-size: 36px;
        font-weight: 300 !important;
        color: #555555;
    }

    .property-icons {
        .property-details {
            font-size: 14px;
            font-weight: 600;

            .property-id-icon {
                font-size: 18px;
                margin-right: 40px;
                background-size: 23px;
                background-image: url("../../images/print/property-id.png");
                padding-left: 33px;
            }

            .property-bed-icon,
            .property-bathroom-icon,
            .property-area-icon,
            .property-floor-icon,
            .property-lux-patch {
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 30px;
                background-size: 20px;
            }

            .property-bed-icon {
                background-image: url("../../images/print/bed.png");
                background-size: 25px;
                padding-left: 35px;
            }

            .property-bathroom-icon {
                background-image: url("../../images/print/bathroom.png");
            }

            .property-area-icon {
                background-image: url("../../images/print/area.png");
            }

            .property-floor-icon {
                background-image: url("../../images/print/floor.png");
            }

            .property-lux-patch {
                background-image: url("../../images/print/lux-patch.png");
            }

            li {
                margin-right: 30px;
            }
        }
    }

    .property-pricing {
        .price {
            font-size: 36px;
            font-weight: 600;
            position: relative;

            small {
                font-size: 18px;
                font-weight: 300;
                text-decoration: line-through;
                position: absolute;
                left: 0;
                top: -20px;
            }
        }
    }

    .property-type {
        p {
            font-size: 18px;
            font-weight: 600;
        }

        span {
            font-weight: 400;
        }
    }

    .property-lists {
        color: darken(#555555, 2);

        h3 {
            font-size: 18px;
            margin-bottom: 1.5em;
        }

        .property-listing li {
            list-style-type: none;
            font-size: 14px;
            font-weight: 400;
            color: darken(#555555, 2);
            line-height: 1.8;
            position: relative;

            i {
                color: #9bbc01;
            }
        }

        .property-listing-type li {
            list-style-type: none;
            font-size: 14px;
            font-weight: 300;
            color: darken(#555555, 2);
            line-height: 1.8;
            position: relative;
            display: flex;

            span {
                display: inline-block;
                min-width: 190px;
                font-weight: 600;
                margin-right: 10px;
            }
        }
    }

    .property-description {
        margin-top: 3em;
        margin-bottom: 3em;
        color: darken(#555555, 2);
        padding-right: 15px;
        padding-left: 15px;
        max-width: 1090px;

        p {
            font-size: 18px;
        }

        h3 {
            font-size: 20px;
            margin-bottom: 1.5em;
        }
    }
}

.image-grid {
    img {
        margin-right: 3em;
        margin-bottom: 2em;
    }
}

.property-layout {
    h4 {
        margin-bottom: 6em;
    }
}

#print-footer {
    @media print {
        &.v1 {
            position: absolute;
            bottom: 0;
        }
    }

    .footer-section {
        padding-bottom: 40px !important;
        padding-top: 40px !important;

        .block-title {
            font-size: 14px;
        }

        .footer-info-list {
            font-weight: 300;

            li {
                padding-left: 35px;
                font-size: 16px;
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            i {
                margin-top: 0.5em;
                font-size: 15px;
                position: absolute;
                left: 15px;
            }
        }

        .agent-box {
            font-size: 16px;
        }

        .footer-icons {
            float: right !important;

            .white {
                filter: brightness(100);
            }

            .footer-icon-list {
                display: flex;
                margin-top: 4em;
                column-gap: 1em !important;
            }

            i {
                background-color: white;
                color: #1b262e;
                width: 30px;
                height: 30px;
                text-align: center;
                padding-top: 0.5em;
            }
        }
    }
}