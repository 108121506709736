.v9 {
    &.group-search,
    &.homepage-search {
        .first-col {
            input {
                width: 100%;
                padding: 0 8px;
                border-right: 1px solid #d4d4d4;
                border-left: 1px solid #d4d4d4;
            }
        }

        .select-type {
            width: 180px;
        }

        .select-city {
            width: 215px;
        }

        .select-cityArea {
            width: 215px;
        }

        .select-roomNo {
            width: 160px;
        }

        &.search-selects {
            .input-column {
                input {
                    width: 110px;
                }
            }

            .select-column {
                /*width: auto;*/
                height: 50px;
                border-right: 1px solid #d4d4d4;

                .SumoSelect {
                    > .CaptionCont {
                        border: none;
                        margin-left: 1px;
                        width: auto;
                        text-align: left;
                    }
                }
            }

            .range-col {
                max-width: 18.28%;
                flex: 0 0 18.28%;

                .range-wrapper {
                    right: 0;
                    left: unset;
                }
            }

            .group-price-col {
                max-width: 16.21% !important;
                flex: 0 0 16.21% !important;
            }
        }
    }

    .input-column-search-group-id {
        width: 132px;
    }

    &.group-search {
        &.search-selects {
            .input-column {
                input {
                    width: 100%;
                }
            }
        }
    }
}
