.accordion {
    .card {
        margin-bottom: 10px;
        overflow: visible;

        .card-header {
            padding: 0;
        }

        .card-body {
            border: 1px solid #D4D4D4;
            border-top: 0;

            p {
                margin-top: 0;
                margin-bottom: 20px;
                font-weight: 400;

                &:last-of-type {
                    margin-bottom: 0;
                }

                b,
                strong {
                    font-weight: 700;
                }
            }
        }
    }

    h2 {
        color: #FFF;
        font-size: 15px;
        font-weight: 500;

        button {
            background: #555;
            color: #FFF;
            font-size: 15px;
            font-weight: 500;
            padding: 15px 25px;
            position: relative;

            &:focus,
            &:active,
            &:hover {
                color: #FFF;
                text-decoration: none;
            }

            &.collapsed {
                background: #f5f3f2;
                border: 1px solid #d4d4d4;
                color: #555;

                &:hover {
                    color: #555;
                }

                .icons {
                    i.fa-plus-circle {
                        opacity: 0;
                    }

                    i.fa-minus-circle {
                        opacity: 1;
                    }
                }
            }

            .icons {
                position: absolute;
                right: 20px;
                top: 15px;

                i {
                    position: absolute;
                    right: 0;
                    top: 2px;
                    font-size: 18px;
                    transition: opacity .3s;

                    &.fa-plus-circle {
                        opacity: 1;
                    }

                    &.fa-minus-circle {
                        opacity: 0;
                    }
                }
            }
        }
    }

}