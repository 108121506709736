ul {
    &.project-details {
        li {
            font-size: 16px;
            font-weight: 300;

            strong {
                font-weight: 600;
            }
        }
    }
}

#project {
    .property-section {
        &.bg {
            border: none;
            margin-top: 80px;
        }
    }
}

.project-properties {
    form {
        width: 100%;
        border: 1px solid #d7d7d7;
        margin-bottom: 30px;
        // padding: 5px 0 5px 5px;

        .form-inline-title {
            font-size: 14px;
            text-transform: uppercase;
            border-right: 1px solid #d7d7d7;
            padding: 10px 20px;
            color: color('primary');

            @media (max-width: 768px) {
                border: none;
                padding-bottom: 0;
            }
        }

        .input-title {
            font-size: 14px;
            color: #444444;
            text-align: right;
            line-height: 40px;
            max-width: 6%;

            @media (max-width: 992px) {
                max-width: 8.33333%;
            }

            @media (max-width: 768px) {
                max-width: 100%;
                width: 100%;
                flex: 100%;
                text-align: left;
                padding-left: 20px;
            }
        }

        input {
            border: 1px solid #d7d7d7;
            font-size: 14px;
            line-height: 28px;
            padding: 0 10px;
            border-radius: 0;
            margin: 5px 0 6px 0;
        }

        .input-col {
            @media (max-width: 768px) {
                padding-left: 20px;
                padding-right: 20px;

                &.no-padding {
                    padding-right: 0;
                }
            }
        }

        .col-submit {
            button {
                width: 43px;
                height: 43px;
                padding: 0;
                position: relative;
                top: -1px;
                right: -1px;
                margin-bottom: -2px;
            }

            @media (max-width: 768px) {
                width: 100%;

                button {
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}