@import "~sumoselect/sumoselect.min.css";

$colors-custom-select: (
        'checkbox': color('primary'),
);

.SumoSelect {
    width: 100%;

    &:hover {
        cursor: pointer;

        > .CaptionCont {
            box-shadow: none;
            border-color: #d4d4d4;
        }
    }

    > .CaptionCont {
        padding: 0;
        border: 1px solid #d4d4d4;
        border-radius: 0;
        box-shadow: none;

        > span {
            line-height: 38px;
            padding-left: 15px;
            font-size: 14px;

            &:first-letter{
                text-transform: uppercase;
            }
        }

        > label {
            margin: 0 15px 0 0;
            width: auto;

            > i {
                background: none;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 38px;
                width: auto;
                height: auto;
                position: relative;

                &::before {
                    content: "\f107";
                }
            }
        }
    }

    &.open {
        > .optWrapper {
            top: 41px;
            border-radius: 0;

            > .options {
                border-radius: 0;

                li {
                    &.opt {
                        padding: 8px 15px;
                    }

                    label {
                        font-size: 1rem;
                        margin-bottom: 0;

                        &:first-letter{
                            text-transform: uppercase;
                        }
                    }
                }
            }

            &.multiple {
                > .options {
                    li {
                        &.opt {
                            padding-left: 40px;

                            &.selected {
                                span {
                                    i {
                                        background-color: color('checkbox', '', '', $colors-custom-select);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}