.notification-modal {

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    border: 1px solid rgba(color('primary'), 0.3);
    width: 450px;

    @media(max-width: 768px) {
      width: 400px;
    }

    @media(max-width: 425px) {
      width: 320px;
    }

    .modal-top {
      padding: 10px 20px;
      background-color: color('primary');
      color: #eee;

      p {
        margin: 0;
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        width: 49.99%;
        line-height: 2;
      }

      button {
        color: #fff;

        span {
          font-size: 40px;
        }
      }
    }

    .return-message {
      margin: 20px 0 0 20px;
      font-size: 20px;

      &.success {
        color: color('primary');
        font-weight: 500;
      }

      &.error {
        color: #b04141;
      }
    }

    #stored_search_frequency {
      padding: 20px 20px 10px 20px;

      label {
        font-size: 15px;
      }
    }

    .save-frequency-btn {
      padding: 8px 18px;
      margin: 0px 20px 20px 20px;
      text-transform: uppercase;
      color: #fff;
      background-color: color('primary');
      font-size: 13px;
      font-weight: 300;


      i {
        padding-left: 10px;

        &::before {
          content: "\f0c7";
        }
      }
      &[disabled] {
        background: #8896b0;
      }

      &[disabled]:hover {
        cursor: default;
      }



    }
  }
}