@import '~photoswipe/dist/photoswipe.css';
@import '~photoswipe/dist/default-skin/default-skin.css';

.gallery {
    width: 100%;
    position: relative;

    .gallery-item {
        width: 100%;
        cursor: pointer;
    }

    .navi {
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 30px;
        width: 30px;
        height: 30px;

        &.navi-img {
            width: 11px;
            height: 18px;

            &.navi-prev {
                transform: translateY(-50%) rotate(180deg);
            }

            @media(min-width: 768px) {
                width: 21px;
                height: 35px;
            }
        }

        &.navi-prev {
            left: 25px;
            color: #FFF;
        }

        &.navi-next {
            right: 25px;
            color: #FFF;
        }

        &.slick-disabled {
            opacity: 0.2;
            pointer-events: none;
            cursor: default;
        }

        i {
            font-size: 2.142857142857143rem;
            font-weight: 600;
        }
    }

    .navi {
        color: #FFF;
    }

    @media(min-width: 420px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.lb-override {
    .pswp__bg {
        background: #000;
    }

    .pswp__button {
        height: 40px;
        display: inline-flex;
        align-items: center;
    }

    .pswp__button,
    .pswp__button--arrow--left:before,
    .pswp__button--arrow--right:before {
        // background-image: url('../../../images/icons/photoswipe/default-skin.png'); // TODO
        // background-color: rgba(255, 255, 255, 0.6);
        content: none;
    }

    .pswp__button--arrow--left:before,
    .pswp__button--arrow--right:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 30px;
        opacity: 1;
        color: #FFF;
        background: none;
        position: absolute;
        top: 0;
    }

    .pswp__button--arrow--left {
        margin-left: 10px;
        text-align: right;

        &:before {
            content: "\f104";
        }
    }

    .pswp__button--arrow--right {
        margin-right: 10px;
        text-align: left;

        &:before {
            content: "\f105";
        }
    }

    .pswp__button {
        i {
            font-size: 40px;
            color: #FFF;
        }
    }

    .pswp__button--addthis {
        background-position: -44px -44px;
    }

    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
        background: none;
        width: 60px;
    }

    .pswp__container {
        padding-top: 150px;
        padding-bottom: 50px;
    }

    .pswp__top-bar {
        background-color: #000;
        height: 50px;
    }

    .pswp__counter {
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        height: 50px;
        display: inline-flex;
        align-items: center;
        letter-spacing: -2px;
    }

    .pswp__caption {
        bottom: initial;
        top: 50px;
        background-color: transparent;
        height: 25px;
        width: auto;
        left: 14px;

        @media(min-width: 576px) {
            top: 0;
            left: 80px;
        }

        @media(max-width: 576px) {
            right: 14px;
        }

        .pswp__caption__left {
            color: #FFF;
            font-size: 18px;
            font-weight: 400;
            height: 100%;
            display: inline-flex;
            align-items: center;
        }
    }
}