.default-form {

    input {
        font-size: 14px;
        line-height: 38px;
        padding: 0 15px;
        height: auto;
        border: 1px solid #d4d4d4;
        transition: height 0.5s linear;
        margin-left: 0 !important;
    }

    textarea {
        padding: 15px;
        min-height: 90px;
    }

    .required {
        position: relative;

        &:after {
            content: "\f005";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 8px;
            color: color('form-stars', '', '', $colors-form-default);
            position: absolute;
            right: 1px;
            top: -7px;
        }
    }

    button {
        &[type=submit] {
            font-size: 14px;
            line-height: 1;
            color: #FFF;
            text-transform: uppercase;
            padding: 12px 15px;
            min-width: 170px;
            text-align: left;

            i {
                font-size: 16px;
                float: right;
                margin-left: 15px;
            }
        }
    }
}